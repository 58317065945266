import react, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const BankCard = ({bank}) => {

    const [isVideo, setIsVideo] = useState(false);
    const [bankImg, setImage] = useState("");
    const [co2Save, setCo2Save] = useState(0.0);
    const [poster, setPoster] = useState("");

    const fetchBankPhoto = () => {
        if (bank.image) {
            if (bank.image.slice(-1) === "4") {
                setIsVideo(true);
            }
            setImage("https://api.fundraiserecycle.com/media/" + bank.image)
            setPoster("https://api.fundraiserecycle.com/media/" + bank.image.replace(".mp4", ".jpg"))
        } else {
            setIsVideo(true);
            setImage("https://api.fundraiserecycle.com/media/bank-images/Jake%20Tutorial%20Reduced.mp4#t=0.1")
            setPoster("https://api.fundraiserecycle.com/media/bank-images/Jake%20Tutorial%20Reduced.jpg")
        }
        
    }

    useEffect(() => {
        fetchBankPhoto();
        setCo2Save((parseFloat(bank.quantityRecycled.split(',').join(""))) * 3.6)
    })

    return (
        <Container>
        <a href={`/bank?bankId=${bank.bankId}`} target="_blank"><Title>{bank.name}</Title></a>
            <BankInfo>
                <Column>
                    
                    <VideoContainer>
                    {
                        bankImg ?
                            isVideo ?
                            <figure><video preload="metadata" src={bankImg} poster={poster}></video></figure> :
                            <img src={bankImg} alt="" /> :
                            <figure><video preload="metadata" src={`https://fundraise-recycle.s3.us-west-002.backblazeb2.com/fr-bank-default.mp4#t=0.1`}></video></figure>
                    }
                    </VideoContainer>
                </Column>
                <Column>
                    <StatsContainer>
                        <p>Total Recycled: {bank.quantityRecycled}kg</p>
                        <p>Total Raised: £{bank.moneyRaised}</p>
                        <p>Minimum CO2 Saved: {co2Save.toFixed(0)}kg</p>
                    </StatsContainer>
                </Column>
            </BankInfo>
        </Container>
    )
}

export default BankCard;

const Container = styled.div`
    border-bottom: 2px solid black;
    padding-bottom: 8px;
`

const BankInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
`

const Title = styled.h3`
    margin-left: 12px;
    margin-bottom: 0;
    text-decoration: none;

    a {
        text-decoration: none;
    }
`

const VideoContainer = styled.div`
    width: 100%;
    overflow: hidden;

    figure {
        margin: 0;
    }

    video {
        width: 100%;
    }
`

const StatsContainer = styled.div`

`