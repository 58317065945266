import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SharePopup from '../components/SharePopup';
import axios from 'axios';

const CampaignInvitation = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const form = useRef();
    const [sharePopupActive, setSharePopup] = useState(false);
    const [searchParams] = useSearchParams();
    const [campaign, setCampaign] = useState("");
    const [bank, setBank] = useState();

    const getCampaignInfo = async () => {
        console.log("Getting campaign info")
        if (!campaign) {
            if (searchParams.get('campaignid')) {
                console.log("given a campaign id")
                axios
                    .get(`${apiUrl}/collectionrequest/${searchParams.get('campaignid')}`)
                    .then((res) => {
                        console.log("GOT CAMPAIGN DATA:")
                        console.log(res.data)
                        setCampaign(res.data)
                    })
                    .catch((err) => {
                        console.log("CAMPAIGN ERROR:")
                        console.log(err)
                    })
            } else {
                axios
                    .get(`${apiUrl}/collectionrequest/1`)
                    .then((res) => {
                        console.log("GOT CAMPAIGN DATA:")
                        console.log(res.data)
                        setCampaign(res.data)
                    })
                    .catch((err) => {
                        console.log("CAMPAIGN ERROR:")
                        console.log(err)
                    })
            }
        }
    }

    const getBankInfo = async () => {
        console.log("Getting Bank Info")
        if (campaign && !bank) {
            axios
                .get(`${apiUrl}/bank/${campaign.bankId}`)
                .then((res) => {
                    console.log("GOT BANK DATA:")
                    console.log(res.data)
                    setBank(res.data)
                })
                .catch((err) => {
                    console.log("BANK ERROR:")
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        getCampaignInfo();
    }, []);

    useEffect(() => {
        getBankInfo();
    }, [campaign])

    return (
        <PageContainer>
            {
                sharePopupActive ?
                <SharePopup exitFunction={setSharePopup} /> :
                ""
            }
            <Header />
            <PageContent>
                <Title>Fundraising Campaign</Title>
                <Subtitle>You've been invited by {campaign ? campaign.name : "CAMPAIGN NOT FOUND"} to join their fundraising campain for {bank ? bank.name : "BANK NOT FOUND"}.</Subtitle>
                <Subtitle>The collection has been booked for {campaign ? campaign.date : "CAMPAIGN NOT FOUND"}. To participate in the fundraising campain for {bank ? bank.name : "BANK NOT FOUND"}, please clear our your wardrobes and drop off what you don't need before the collection date at:<br />{campaign ? campaign.address : "Fundraise & Recycle\n17 Pease Road\nPeterlee\nSR8 2RD"}.</Subtitle>
                <Subtitle>Want to invite more people to the campaign?</Subtitle>
                <ShareButton className="cta-3" onClick={() => {
                    if (navigator.share) {
                        navigator.share({
                            title: window.title,
                            text: `Would you help me organise a clothes collection on behalf of ${bank ? bank.name : "this organisation"} school?\n\nThey’re fundraising for ${bank ? bank.goal : ","} and by recycling your unwanted clothes we can help them get a step closer.\n\nPlease accept my invite to join the campaign and then share this invitation with friends and family who would also like to support ${bank ? bank.name : "this organisation"}.\nThank you!`,
                            url: window.location.href,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    } else {
                        setSharePopup(true);
                    }
                }}>
                    <p>CLICK HERE!</p>
                </ShareButton>
            </PageContent>
            <Footer />
        </PageContainer>
    )
}

export default CampaignInvitation

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1060px) {
        background: #F8FDFF
    }
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    min-height: 68vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 20px;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    margin: 0;
`

const Subtitle = styled.h2`
`

const Paragraph = styled.p`

`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 0 24px 24px 24px;
    
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input,
    textarea,
    select {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        margin-top: 24px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`

const ShareButton = styled.a`
    background: #BE3258;
    color: white;

    border-radius: 50px;

    p {
        color: white;
        font-weight: bold;
        padding: 0 20px;
    }

    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`