import React from 'react'
import styled from 'styled-components'

const SearchForm = (props) => {

    return (
        <Container className="search-form-container">
            <SearchQueryForm ref={props.refRef} onSubmit={props.onSubmitFn} className="search-form" noValidate>
                <div className="form-fields-container top-level">
                    <div className="form-field-container search-field-container">
                        <input className="form-control search-field" placeholder="Search..." type="text" id="searchLoc" required />
                    </div>
                    <div className="form-field-container">
                        <select defaultValue={16000} id="radius" name="radius">
                            <option value={1600}>+1 Mile</option>
                            <option value={3200}>+2 Miles</option>
                            <option value={6400}>+5 Miles</option>
                            <option value={16000}>+10 Miles</option>
                            <option value={160000}>+100 Miles</option>
                        </select>
                    </div>
                    <button className="btn btn-danger" type="submit">Search</button>
                </div>
            </SearchQueryForm>
        </Container>
    )
}

export default SearchForm

const Container = styled.div`
    @media (max-width: 550px) {
    }
`

const SearchQueryForm = styled.form`
    z-index: 999;
    height: 86px;
    display: flex;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 480px;
    width: 100%;
    padding: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(12, 102, 142, 1);
    border-radius: 64px;

    @media (max-width: 1120px) {
    }

    .form-fields-container {
        display: flex;
        flex-flow: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
    }

    .search-field-container {
        flex: 1 0;
    }

    .search-field-container input {
        width: 100%;
        background: none;
        color: white;
        font-size: 32px;
        font-weight: 400;
        line-height: 44.1px;
        text-align: left;
        text-decoration-skip-ink: none;
        padding-left: 10px;
    }

    .search-field-container input:focus-visible {
        outline: -webkit-focus-ring-color auto 0;
    }

    input::placeholder {
        color: white;
    }

    input,
    select {
        box-sizing: border-box;
        border-color: white;
        height: 38px;
        background: none;
        color: white;
        appearance: none;
    }

    select {
        border-width: 0 0 0 2px;
        width: 128px;
        padding-left: 14px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    /*button {
        border: 0;
        border-radius: 0 24px 0 24px;
        padding: 8px 16px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: normal;
    } */

    button {
        border: 0;
        background: white;
        outline: 0;
        padding: 0;
        border-radius: 64px;
        color: rgba(3, 58, 87, 1);
        padding: 12px 32px;
    }

    .search-field {
        border: 0;
    }

    input, select {
        :active,
        :focus,
        :focus-visible {
            border: 0;
            outline: 0;
            outline: -webkit-focus-ring-color auto 0;
        }   
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }

    .checkout-btn {
        margin: 24px auto;
    }

    @media (max-width: 400px) {
        select {
            width: 80px;
        }
        .search-field-container input {
            font-size: 22px;
        }
        transform: scale(0.8);
    }
    
`